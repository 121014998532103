<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'
import { BASE_IMAGE_URL } from '@src/config/configs'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Category',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      baseImageUrl: BASE_IMAGE_URL,
      title: 'Category',
      targetCategorys: [],
    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    async onLoad() {
      await this.axios
        .get('/api/category')
        .then((res) => {
          this.targetCategorys = res.data
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })
    },
    async handleClickDeleteContent(id) {
      var confirmDel = confirm('Are you sure you want to delete?')

      if (confirmDel) {
        await this.axios
          .delete(`/api/category/${id}`)
          .then((result) => {
            this.onLoad()
          })
          .catch((err) => {
            alert(err.response.data.error.message)
          })
      }
    },

    async getSyncDataVideo() {
      var confirmDel = confirm('Are you sure you want to Sync Data Video?')

      if (confirmDel) {
        await this.axios
          .post(`/api/video/test`)
          .then((result) => {
            this.$toast.success('Data has been sync')
            this.onLoad()
          })
          .catch((err) => {
            alert(err.response.data.error.message)
          })
      }
    },
    async handleClickEditContent(content) {
      // console.log(content)
      this.$router.push({
        path: '/manage/create/category',
        query: { id: content.category_code },
      })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Category Menu</h4>
      </div>
      <div class="col-xl-6">
        <router-link to="/manage/create/category">
          <button
            class="btn btn-primary rounded-pill width-lg float-right aligncenter"
          >
            <feather type="file-plus"></feather>Create
          </button>
        </router-link>
        <button
          class="btn btn-primary rounded-pill width-lg float-right aligncenter mr-2"
          @click="getSyncDataVideo()"
        >
          <feather class="mr-2" type="refresh-cw"></feather> sync
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>

                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(targetCategory, index) in targetCategorys"
                    :key="index"
                  >
                    <td scope="row">{{ index + 1 }}</td>

                    <td scope="row" style="width: 20%;">
                      <img
                        :src="
                          `${baseImageUrl}/${targetCategory.category_image}`
                        "
                        alt
                        class="img-fluid"
                      />
                    </td>
                    <td scope="row" style="width: 35%;">
                      {{ targetCategory.category_name }}
                    </td>

                    <td scope="row">
                      <b-badge
                        v-if="targetCategory.is_coming"
                        variant="secondary"
                        >Comming soon</b-badge
                      >
                      <b-badge v-else variant="primary">Show</b-badge>
                    </td>

                    <td class="d-flex">
                      <router-link
                        :to="`/category/child/${targetCategory.category_code}`"
                      >
                        <button class="btn btn-info p-0 px-2 py-1 mr-2"
                          >View</button
                        >
                      </router-link>

                      <button
                        class="btn btn-warning p-0 px-2 py-1 mr-2"
                        @click="handleClickEditContent(targetCategory)"
                        >Edit</button
                      >

                      <b-button
                        @click="handleClickDeleteContent(targetCategory._id)"
                        class="btn btn-danger p-0 px-2 py-1"
                        v-b-modal.modal-center
                        >Delete</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
